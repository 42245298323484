<template>
	<div class="notification-box">
		<button class="remove-item mark-as-read" @click.prevent.stop="read">
			<i class="fa-regular fa-trash"></i>
		</button>
		<div class="top-panel">
			<small class="category" v-if="data.category">{{ data.category }}</small>
			<small class="category" v-else>{{ $t('notifications.notification') }}</small>
			<small class="datetime">{{ formattedDate }}</small>
		</div>
		<div class="content">
			<div class="text-image">
				<figure class="fit-image image" v-if="data.image">
					<img :src="data.image">
				</figure>
				<div class="text">
					<h4 class="notification-title">{{ data.name }}</h4>
					<p v-if="data.content">{{ data.content }}</p>
				</div>
			</div>
			<div class="info" v-if="data.price">
				<p class="price" v-if="data.price">{{ data.price }}</p>
			</div>
		</div>
		<div class="actions" v-if="action">
			<button class="button style-1" v-if="data.type === 'protocol'" @click.stop.prevent="signProtocol">
				<span class="inner">{{ $t('protocols.sign_protocol') }}</span>
			</button>
			<button class="button style-1" v-else-if="data.type === 'document'" @click.stop.prevent="showDocument">
				<span class="inner">{{ $t('documents.show_document') }}</span>
			</button>
			<button class="button style-1" v-else-if="data.type === 'article'" @click.stop.prevent="showArticle">
				<span class="inner" v-if="data.button">{{ data.button }}</span>
				<span class="inner" v-else>{{ $t('articles.show_article') }}</span>
			</button>
		</div>
	</div>
</template>

<script>
import i18n from "@/i18n";

export default {
	name: "NotificationBox",
	props: ['data'],
	data() {
		return {
			loading: false,
			actionTypes: [
				'protocol',
				'document',
				'article'
			]
		}
	},
	computed: {
		action() {
			return this.actionTypes.includes(this.data.type);
		},
		formattedDate() {
			return this.$moment(this.data.created_at).format('DD.MM.YYYY H:mm:ss');
		}
	},
	methods: {
		showArticle() {
			let id = this.data.data;
			if (!id) {
				return false;
			}

			this.$store.dispatch('closeSidebars');
			if (this.$route.path !== `${i18n.t('routes.articles.path')}/${id}`) {
				this.$router.push(`${i18n.t('routes.articles.path')}/${id}`);
			}
			//this.read();
		},
		showDocument() {
			let id = this.data.data;
			if (!id) {
				return false;
			}

			let promises = [];
			if (!this.$store.state.views.document_categories.length) {
				let documentCategoriesPromise = this.axios.get(`/api/document-categories`);
				promises.push(documentCategoriesPromise);
			}

			let documentPromise = this.axios.get(`/api/documents/${id}`);
			promises.push(documentPromise);

			this.$store.commit('SET_APP_LOADING');
			Promise.all(promises).then((values) => {
				values.forEach(response => {
					if (response.data.document_categories) {
						this.$store.dispatch('setDocumentCategories', response.data.document_categories);
					}
					if (response.data.document) {
						this.$store.dispatch('closeSidebars');
						this.$store.commit('SHOW_DOCUMENTS_EDIT_FORM', response.data.document);
					}
					this.$store.commit('UNSET_APP_LOADING');
				});
			}).catch(() => {
				this.$store.commit('UNSET_APP_LOADING');
				this.$swal.fire({
					icon: 'error',
					title: 'Oops',
					text: this.$t('general.default_error')
				});
			});
		},
		signProtocol() {
			let hash = this.data.data;
			if (!hash) {
				return false;
			}

			this.$store.commit('SET_APP_LOADING');
			this.axios.get(`/api/protocols/${hash}`).then(response => {
				this.$store.commit('UNSET_APP_LOADING');
				this.$store.dispatch('closeSidebars');

				let protocol = response.data.protocol[0];
				protocol.hash = protocol.externe_dok_id;
				protocol.files = [];
				response.data.protocol.forEach(item => {
					protocol.files.push({
						name: item.typ_dokumentu,
						type: item.typ_dokumentu_id,
						url: item.url_dokumentu
					});
				});

				this.$store.commit('SHOW_PROTOCOL_DETAIL', protocol);
			}).catch(() => {
				this.$store.commit('UNSET_APP_LOADING');
				this.$swal.fire({
					icon: 'error',
					title: 'Oops',
					text: this.$t('general.default_error')
				});
			});
		},
		read() {
			this.loading = true;
			let url = '/api/notification/read';
			if (this.data.global) {
				url = '/api/notification/read-global';
			}
			this.axios.put(url, {
				id: this.data.id
			}).then(() => {
				this.$store.commit('REMOVE_NOTIFICATION', this.data.id);
				this.loading = false;
			}).catch(error => {
				this.$swal.fire({
					icon: 'error',
					title: 'Oops',
					text: error.response.data.message
				});
				this.loading = false;
			})
		}
	}
}
</script>

<style scoped lang="scss">
.light-mode {
	.notification-title,
	.category,
	.datetime{
		color: var(--background-color, $backgroundColor);
	}
	.top-panel {
		border-bottom: 1px solid rgba(var(--background-color-rgb), 0.15);
	}
	.notification-box {
		background: #F0F0F2;
		background: var(--white-color, $whiteColor);
		.remove-item {
			border-color: var(--white-color, $whiteColor);
			background: var(--background-color, $backgroundColor);
			color: var(--white-color, $whiteColor);
		}
	}
}

.notification-box {
	padding: 15px;
	background: transparentize($whiteColor, .94);
	border-radius: 5px;
	position: relative;

	.remove-item {
		background: var(--white-color, $whiteColor);
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 100%;
		width: 38px;
		height: 38px;
		font-size: 14px;
		border: 5px solid var(--background-color, $backgroundColor);
		color: var(--primary-color, $primaryColor);
		cursor: pointer;
		transition: $animationDefaultDuration;
		position: absolute;
		right: -19px;
		top: -19px;
		opacity: 0;
		pointer-events: none;

		&:hover {
			color: var(--white-color, $whiteColor);
			background: var(--primary-color, $primaryColor);
		}
	}

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: -4px;
		right: -4px;
		bottom: -4px;
		left: -4px;
		pointer-events: none;
		border: 2px solid var(--secondary-color, $primaryColor);
		border-radius: 8px;
		opacity: 0;
		transition: $animationDefaultDuration;
	}

	&:hover {
		.remove-item {
			opacity: 1;
			pointer-events: auto;
		}

		&:before {
			opacity: 1;
		}
	}

	> .actions {
		margin-top: 16px;

		.button {
			width: 100%;
			padding: 9px 25px;
			font-size: 12px;
		}
	}
}

.loading {
	font-size: 12px;
	line-height: 1;
	color: $whiteColor;
	animation: spinning_animation 1s infinite;
	animation-timing-function: linear;
}

.mark-as-read {
	cursor: pointer;
	background: transparent;
	color: var(--font-color, $fontColor);
	border: none;
	padding: 0;
	line-height: 1;
	font-size: 12px;

	&:hover {
		color: var(--white-color, $whiteColor);
	}
}

.top-panel {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 10px;
	//border-bottom: 1px solid transparentize($whiteColor, .85);
	border-bottom: 1px solid rgba(var(--white-color-rgb), .15);
}

.category,
.datetime{
	font-size: 10px;
	color: var(--white-color, $whiteColor);
}

.date {
	font-size: 9px;
	color: var(--font-color, $fontColor);
}

.content {
	padding-top: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&:first-child {
		padding-top: 0;
	}
}

.text-image {
	display: flex;
	align-items: center;

	p {
		font-size: 10px;
	}
}

.image {
	border-radius: 50%;
	height: 40px;
	width: 40px;
	min-width: 40px;
	overflow-y: hidden;
	margin-right: 20px;
}

.price {
	font-size: 16px;
	color: var(--white-color, $whiteColor);
	margin: 0 25px 0 0;
	white-space: nowrap;

	&:last-child {
		margin-right: 0;
	}
}

.notification-title {
	margin: 0 0 5px;
	font-size: 12px;
	font-weight: 600;
	color: var(--white-color, $whiteColor);

	&:last-child {
		margin-bottom: 0;
	}
}

.info {
	display: flex;
	align-items: center;
	margin-left: 15px;
}

@media($desktopM) {
	.info-actions {
		.button {
			width: initial;
		}
	}
}

@media($tabletL) {
	.price {
		margin-right: 0;
		margin-bottom: 5px;

		&:last-child {
			margin-bottom: 0;
		}
	}
	.info-actions {
		flex-direction: column;
	}
}

@media($tabletS) {
	/*.content {
		flex-direction: column;
		justify-content: center;
	}*/
	.text-image {
		width: 100%;
		margin-bottom: 20px;
		//justify-content: center;

		&:last-child {
			margin-bottom: 0;
		}
	}
	.info-actions {
		width: 100%;
		margin-left: 0;
		flex-direction: row;
		justify-content: center;

		.price {
			margin-right: 20px;
			margin-bottom: 0;

			&:last-child {
				margin-right: 0;
			}
		}
	}
}

@media($mobileM) {
	.panel-box {
		padding: 20px;
	}
	.content {
		padding-top: 15px;
	}
	.info-actions {
		flex-direction: column;

		.actions,
		.button {
			width: 100%;
		}

		.price {
			margin-right: 0;
			margin-bottom: 5px;
		}
	}
}
</style>
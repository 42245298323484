<template>
	<div id="app" :class="extraClasses">
		<div class="app-layout" @click="closeSidebars" v-if="$store.state.app.appLayout">
			<css-variables/>
			<main-nav v-if="!$store.state.popups.showUserActivationPopup"/>
			<mobile-nav v-if="!$store.state.popups.showUserActivationPopup"/>
			<user-activation-popup v-if="$store.state.popups.showUserActivationPopup"/>
			<user-deletion-popup v-if="$store.state.popups.showUserDeletionPopup"/>
			<complete-registration-popup v-if="$store.state.popups.showCompleteRegistrationPopup"/>
			<div class="app-content" :style="`padding-top: ${safeAreaHeight}px;`"
			     :class="$store.state.app.appLoading ? 'loading' : ''" v-else>
				<app-header v-if="!$store.state.app.native"/>
				<native-header v-else/>
				<router-view/>
				<app-footer/>
			</div>
			<notifications-sidebar v-if="$store.state.auth.user.id"/>
			<settings-sidebar/>
			<documents-create-form-sidebar/>
			<documents-edit-form-sidebar/>
			<contracts-edit-form-sidebar/>
			<personal-documents-create-form-sidebar/>
			<personal-documents-edit-form-sidebar/>
			<cards-create-form-sidebar/>
			<cards-edit-form-sidebar/>
			<protocol-detail-sidebar/>
			<online-services-sidebar/>
			<portfolio-sidebar/>
			<barcode-overlay v-if="$store.state.overlays.showBarcodeOverlay"/>
			<safe-area-top :height="safeAreaHeight"/>
			<setup-pin-popup v-if="$store.state.popups.showSetupPinPopup"/>
			<ocr-popup v-if="$store.state.popups.showOcrPopup" :link="$store.state.popups.showOcrPopup"/>
		</div>
		<div class="public-layout" :class="pageClass" v-else>
			<public-header v-if="!$store.state.app.native"/>
			<css-variables/>
			<router-view/>
			<login-popup/>
			<registration-popup/>
			<reset-password-popup/>
			<public-footer v-if="!$store.state.app.native"/>
		</div>
	</div>
</template>

<style lang="scss">
	@import "@/assets/sass/screen";
</style>

<script>
	import CssVariables from "./components/globals/CssVariables";
	import MainNav from "./components/partials/MainNav";
	import MobileNav from "./components/partials/MobileNav";
	import AppHeader from "./components/partials/AppHeader";
	import NativeHeader from "./components/partials/NativeHeader";
	import NotificationsSidebar from "./components/sidebars/NotificationsSidebar";
	import DocumentsCreateFormSidebar from "./components/sidebars/DocumentsCreateFormSidebar";
	import DocumentsEditFormSidebar from "./components/sidebars/DocumentsEditFormSidebar";
	import ContractsEditFormSidebar from "./components/sidebars/ContractsEditFormSidebar";
	import SettingsSidebar from "./components/sidebars/SettingsSidebar";
	import PersonalDocumentsCreateFormSidebar from "./components/sidebars/PersonalDocumentsCreateFormSidebar";
	import PersonalDocumentsEditFormSidebar from "./components/sidebars/PersonalDocumentsEditFormSidebar";
	import CardsCreateFormSidebar from "./components/sidebars/CardsCreateFormSidebar";
	import CardsEditFormSidebar from "./components/sidebars/CardsEditFormSidebar";
	import ProtocolDetailSidebar from "./components/sidebars/ProtocolDetailSidebar";
	import OnlineServicesSidebar from "@/components/sidebars/OnlineServicesSidebar";
	import PortfolioSidebar from "@/components/sidebars/PortfolioSidebar";
	import PublicHeader from "./components/partials/PublicHeader";
	import PublicFooter from "@/components/partials/PublicFooter";
	import LoginPopup from "./components/popups/LoginPopup";
	import RegistrationPopup from "./components/popups/RegistrationPopup";
	import ResetPasswordPopup from "./components/popups/ResetPasswordPopup";
	import BarcodeOverlay from "./components/overlays/BarcodeOverlay";
	import SafeAreaTop from "./components/partials/SafeAreaTop";
	import SetupPinPopup from "./components/popups/SetupPinPopup";
	import UserActivationPopup from "./components/popups/UserActivationPopup";
	import automaticLogoutMixin from "@/mixins/automaticLogoutMixin";
	import {FacebookLogin} from "@capacitor-community/facebook-login";
	import {GoogleAuth} from "@codetrix-studio/capacitor-google-auth";
	import AppFooter from "./components/partials/AppFooter";
	import OcrPopup from "./components/popups/OcrPopup";
	import CompleteRegistrationPopup from "./components/popups/CompleteRegistrationPopup";
	import UserDeletionPopup from "./components/popups/UserDeletionPopup";

	export default {
		name: "App",
		components: {
			CssVariables,
			AppHeader,
			AppFooter,
			NativeHeader,
			MainNav,
			MobileNav,
			NotificationsSidebar,
			SettingsSidebar,
			DocumentsCreateFormSidebar,
			DocumentsEditFormSidebar,
			ContractsEditFormSidebar,
			PersonalDocumentsCreateFormSidebar,
			PersonalDocumentsEditFormSidebar,
			CardsCreateFormSidebar,
			CardsEditFormSidebar,
			ProtocolDetailSidebar,
			OnlineServicesSidebar,
			PortfolioSidebar,
			PublicHeader,
			PublicFooter,
			LoginPopup,
			RegistrationPopup,
			ResetPasswordPopup,
			BarcodeOverlay,
			SafeAreaTop,
			SetupPinPopup,
			UserActivationPopup,
			OcrPopup,
			CompleteRegistrationPopup,
			UserDeletionPopup,
		},
		mixins: [automaticLogoutMixin],
		methods: {
			closeSidebars(e) {
				let target = e.target;
				if (target.classList.contains('default-sidebar')) {
					return false;
				}

				let sidebar = target.closest('.default-sidebar');
				if (sidebar) {
					return false;
				}

				this.$store.dispatch('closeSidebars');
			},
			initSocialAuth() {
				FacebookLogin.initialize({appId: process.env.VUE_APP_FB_ID});
				if (this.$store.state.app.isNative) {
					let clientId;
					if (this.$store.state.app.platform === 'ios') {
						clientId = process.env.VUE_APP_GOOGLE_IOS_ID;
					} else {
						clientId = process.env.VUE_APP_GOOGLE_ANDROID_ID;
					}

					GoogleAuth.initialize({
						client_id: clientId,
						scopes: ['profile', 'email'],
						grantOfflineAccess: true,
					});
				} else {
					(function (d, s, id) {
						let js, fjs = d.getElementsByTagName(s)[0];
						if (d.getElementById(id)) {
							return;
						}
						js = d.createElement(s);
						js.id = id;
						js.src = 'https://accounts.google.com/gsi/client';
						fjs.parentNode.insertBefore(js, fjs);
					}(document, 'script', 'google-jssdk'));
				}
			},

			checkInvitation() {
				let urlParams = new URLSearchParams(window.location.search);
				if (this.$store.state.auth.isLoggedIn || (!urlParams.has('invite') && !urlParams.has('agent'))) {
					return false;
				}

				let invite = urlParams.get('invite');
				if (invite) {
					this.axios.post('/api/invite-user', {
						hash: invite,
					}).then(response => {
						this.$store.commit('SET_INVITE_HASH', invite);
						this.$store.commit('SET_INTRANET_USER', response.data.intranet_user);
						this.$store.dispatch('openRegistrationPopup');
					});

					return true;
				}

				let agent = urlParams.get('agent');
				if (agent) {
					this.$store.commit('SET_INVITE_AGENT', agent);
					this.$store.dispatch('openRegistrationPopup');
				}
			},
			checkPasswordReset() {
				let urlParams = new URLSearchParams(window.location.search);
				if (this.$store.state.auth.isLoggedIn || !urlParams.has('reset-password')) {
					return false;
				}

				this.$store.dispatch('openResetPasswordPopup');
			},
			checkUserMasquerade() {
				let urlParams = new URLSearchParams(window.location.search);
				if (this.$store.state.auth.isLoggedIn || !urlParams.has('user-masquerade')) {
					return false;
				}

				let hash = urlParams.get('user-masquerade');

				this.$store.commit('SET_APP_LOADING');
				this.axios.post('/api/user/masquerade-login', {
					hash: hash,
				}).then((response) => {
					this.$store.dispatch('processLogin', response);
					this.$store.commit('UNSET_APP_LOADING');
				}).catch((error) => {
					this.$swal.fire({
						icon: 'error',
						title: 'Oops',
						text: error.response.data.message
					});
					this.$store.commit('UNSET_APP_LOADING');
				});
			}
		},
		computed: {
			safeAreaHeight() {
				return this.$store.state.app.safeArea.top;
			},
			extraClasses() {
				let classes = [];

				if (this.$store.state.app.native) {
					classes.push('native');
				} else {
					classes.push('web');
				}

				classes.push(process.env.VUE_APP_TYPE);

				if (!this.$store.state.app.darkMode) {
					classes.push('light-mode');
				}

				return classes.join(' ');
			},
			pageClass() {
				let classes = [];
				if (this.$store.state.app.appLoading) {
					classes.push('loading');
				}

				if (!this.$route.name) {
					classes.join(' ');
					return classes;
				}

				let name = this.$route.name.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`);
				if (name[0] === '-') {
					name = name.substring(1);
				}

				classes.push(name);

				return classes.join(' ');
			},
		},

		mounted() {
			this.checkInvitation();
			this.checkPasswordReset();
			this.checkUserMasquerade();
			document.body.classList.remove('before-mounted');
		},
		created() {
			this.$store.dispatch('setSafeArea');
			this.$store.dispatch('checkDefaultUserLogin');
			this.$store.dispatch('checkAppVersion');
			this.initSocialAuth();
		}
	}
</script>
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from "../store";
import _ from 'lodash';
import Landing from "../views/Landing";
import Home from '../views/Home.vue';
import OnlineServices from "../views/OnlineServices";
import OnlineServicesHome from "../views/OnlineServicesHome";
import OnlineServiceDetail from "../views/OnlineServiceDetail";
import Documents from "../views/Documents";
import DocumentCategory from "../views/DocumentCategory";
import DocumentsHome from "../views/DocumentsHome";
import DocumentsAll from "../views/DocumentsAll";
import Products from "../views/Products";
import ProductsHome from "../views/ProductsHome";
import ProductDetail from "../views/ProductDetail";
import Articles from "../views/Articles";
import ArticlesHome from "../views/ArticlesHome";
import ArticleDetail from "../views/ArticleDetail";
import Contacts from "../views/Contacts";
import Cards from "../views/Cards";
import Profile from "../views/Profile";
import PageNotFound from "../views/PageNotFound";
import BranchDetail from "../views/BranchDetail";
import PartnerDetail from "../views/PartnerDetail";
import MobilityDetail from "../views/MobilityDetail";
import PageDetail from "../views/PageDetail";
import Investments from "@/views/Investments";
import InvestmentsHome from "@/views/InvestmentsHome";
import InvestmentsEic from "@/views/InvestmentsEic";
import InvestmentsEicAccount from "@/views/InvestmentsEicAccount";
import InvestmentsIad from "@/views/InvestmentsIad";
import InvestmentsIadAccount from "@/views/InvestmentsIadAccount";
import InvestmentsConseq from "@/views/InvestmentsConseq";
import InvestmentsConseqAccount from "@/views/InvestmentsConseqAccount";
import InvestmentsInvestika from "@/views/InvestmentsInvestika";
import InvestmentsInvestikaAccount from "@/views/InvestmentsInvestikaAccount";
import i18n from "../i18n";

import { SplashScreen } from "@capacitor/splash-screen";

Vue.use(VueRouter)

const routes = [
	{
		path: i18n.t('routes.landing.path'),
		name: 'Landing',
		component: Landing,
		meta: {
			public: true
		}
	},
	{
		path: i18n.t('routes.home.path'),
		name: 'Home',
		component: Home
	},
	{
		path: i18n.t('routes.online_services.path'),
		component: OnlineServices,
		children: [
			{
				path: '',
				name: 'OnlineServicesHome',
				component: OnlineServicesHome
			},
			{
				path: `${i18n.t('routes.mobility.sub_path')}/:id`,
				name: 'MobilityDetail',
				component: MobilityDetail
			},
			{
				path: ':slug',
				name: 'OnlineServiceDetail',
				component: OnlineServiceDetail
			},
		]
	},
	{
		path: i18n.t('routes.documents.path'),
		component: Documents,
		children: [
			{
				path: '',
				name: 'DocumentsHome',
				component: DocumentsHome
			},
			{
				path: i18n.t('routes.all_documents.sub_path'),
				name: 'DocumentsAll',
				component: DocumentsAll,
			},
			{
				path: ':category',
				name: 'DocumentCategory',
				component: DocumentCategory,
			},
		]
	},
	{
		path: i18n.t('routes.products.path'),
		component: Products,
		children: [
			{
				path: '',
				name: 'ProductsHome',
				component: ProductsHome
			},
			{
				path: ':id',
				name: 'ProductDetail',
				component: ProductDetail,
			},
		]
	},
	{
		path: i18n.t('routes.articles.path'),
		component: Articles,
		children: [
			{
				path: '',
				name: 'ArticlesHome',
				component: ArticlesHome
			},
			{
				path: ':id',
				name: 'ArticleDetail',
				component: ArticleDetail,
			},
		]
	},
	{
		path: i18n.t('routes.contacts.path'),
		name: 'Contacts',
		component: Contacts
	},
	{
		path: `${i18n.t('routes.branch.path')}/:id`,
		name: 'BranchDetail',
		component: BranchDetail
	},
	{
		path: `${i18n.t('routes.partner.path')}/:id`,
		name: 'PartnerDetail',
		component: PartnerDetail
	},
	{
		path: i18n.t('routes.cards.path'),
		name: 'Cards',
		component: Cards
	},
	{
		path: i18n.t('routes.investments.path'),
		name: 'Investments',
		component: Investments,
		children: [
			{
				path: '',
				name: 'InvestmentsHome',
				component: InvestmentsHome
			},
			{
				path: i18n.t('routes.investments_eic.sub_path'),
				name: 'InvestmentsEic',
				component: InvestmentsEic
			},
			{
				path:  `${i18n.t('routes.investments_eic.sub_path')}/:id`,
				name: 'InvestmentsEicAccount',
				component: InvestmentsEicAccount
			},
			{
				path: i18n.t('routes.investments_iad.sub_path'),
				name: 'InvestmentsIad',
				component: InvestmentsIad
			},
			{
				path:  `${i18n.t('routes.investments_iad.sub_path')}/:slug`,
				name: 'InvestmentsIadAccount',
				component: InvestmentsIadAccount
			},
			{
				path: i18n.t('routes.investments_conseq.sub_path'),
				name: 'InvestmentsConseq',
				component: InvestmentsConseq
			},
			{
				path:  `${i18n.t('routes.investments_conseq.sub_path')}/:id`,
				name: 'InvestmentsConseqAccount',
				component: InvestmentsConseqAccount
			},
			{
				path: i18n.t('routes.investments_investika.sub_path'),
				name: 'InvestmentsInvestika',
				component: InvestmentsInvestika
			},
			{
				path:  `${i18n.t('routes.investments_investika.sub_path')}/:id`,
				name: 'InvestmentsInvestikaAccount',
				component: InvestmentsInvestikaAccount
			},
		]
	},
	{
		path: i18n.t('routes.profile.path'),
		name: 'Profile',
		component: Profile
	},
	{
		path: '/404',
		name: 'PageNotFound',
		component: PageNotFound,
	},
	{
		path: '/:slug',
		name: 'PageDetail',
		component: PageDetail,
		meta: {
			public: true
		}
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 };
	}
});

function nextFactory(to, from, next) {
	let fromPublic = false;
	if (to.name === 'Home' && from.meta.public) {
		fromPublic = true;
	}

	if (!fromPublic) {
		store.commit('SET_APP_LAYOUT', !to.meta.public);
	}

	switch (to.name) {
		case 'Home':
		case 'OnlineServicesHome':
			store.dispatch('getDashboardData').then(() => {
				if (fromPublic) {
					store.commit('SET_APP_LAYOUT', !to.meta.public);
				}
				next();
			});
			break;
		case 'DocumentsAll':
		case 'DocumentsHome':
			store.dispatch('getDocumentsData').then(() => next());
			break;
		case 'ProductsHome':
			store.dispatch('getProductsData').then(() => next());
			break;
		case 'ArticlesHome':
			store.dispatch('getArticlesData').then(() => next());
			break;
		case 'ProductDetail':
			store.dispatch('getProductData', to.params.id).then(() => next());
			break;
		case 'ArticleDetail':
			store.dispatch('getArticleData', to.params.id).then(() => next());
			break;
		case 'OnlineServiceDetail':
			store.dispatch('getOnlineServiceData', to.params.slug).then(() => next());
			break;
		case 'DocumentCategory':
			store.dispatch('getDocumentCategoryData', to.params.category).then(() => next());
			break;
		case 'Cards':
			store.dispatch('getCardsData').then(() => next());
			break;
		case 'MobilityDetail':
			store.dispatch('getMobilityData', to.params.id).then(() => next());
			break;
		case 'InvestmentsHome':
		case 'InvestmentsEic':
		case 'InvestmentsIad':
		case 'InvestmentsConseq':
		case 'InvestmentsInvestika':
			store.dispatch('getInvestmentsData').then(() => next());
			break;
		case 'InvestmentsEicAccount':
			store.dispatch('getInvestmentsEicAccountData', to.params.id).then(() => next());
			break;
		case 'InvestmentsIadAccount':
			store.dispatch('getInvestmentsIadAccountData', to.params.slug).then(() => next());
			break;
		case 'InvestmentsConseqAccount':
			store.dispatch('getInvestmentsConseqAccountData', to.params.id).then(() => next());
			break;
		case 'InvestmentsInvestikaAccount':
			store.dispatch('getInvestmentsInvestikaAccountData', to.params.id).then(() => next());
			break;
		case 'Contacts':
		case 'BranchDetail':
		case 'PartnerDetail':
			store.dispatch('getContactsData').then(() => {
				if (to.name === 'BranchDetail') {
					let branch = store.state.views.branches.find(item => {
						return item.id_satelit === to.params.id;
					});

					if (!branch) {
						router.push('/404');
						return false;
					}
				}
				if (to.name === 'PartnerDetail') {
					let partner = store.state.views.partners.find(item => {
						return item.code === to.params.id;
					});

					if (!partner) {
						router.push('/404');
						return false;
					}
				}

				next();
			});
			break;
		case 'PageDetail':
			store.dispatch('getPageData', to.params.slug).then(() => next());
			break;
		default:
			next();
			break;
	}
}

router.beforeEach((to, from, next) => {
	//Auth Middleware
	if (to.meta.public) {
		nextFactory(to, from, next);
		return true;
	}

	if (store.state.auth.isLoggedIn && !_.isEmpty(store.state.auth.user)) {
		nextFactory(to, from, next);
	} else {
		store.dispatch('getUser').then(() => {
			nextFactory(to, from, next);
		}).catch(() => {
			if (from.name === 'Landing') {
				return false;
			}
			router.push(i18n.t('routes.landing.path'));
		});
	}
});

router.afterEach(() => {
	if (store.state.app.native) {
		SplashScreen.hide();
	}
	store.commit('UNSET_SHOW_NAV');
});


export default router

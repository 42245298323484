import { render, staticRenderFns } from "./NotificationBox.vue?vue&type=template&id=8bdd768a&scoped=true"
import script from "./NotificationBox.vue?vue&type=script&lang=js"
export * from "./NotificationBox.vue?vue&type=script&lang=js"
import style0 from "./NotificationBox.vue?vue&type=style&index=0&id=8bdd768a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8bdd768a",
  null
  
)

export default component.exports
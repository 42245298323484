<template>
	<div class="store-buttons-box">
		<a :href="iosLink" class="image-button apple-store" target="_blank">
			<img :src="getLocalAsset('img/logo/apple-store.svg')">
		</a>
		<a :href="googleLink" class="image-button google-play" target="_blank">
			<img :src="getLocalAsset('img/logo/google-play.svg')">
		</a>
	</div>


</template>

<script>
	import pageFunctionsMixin from "../../mixins/pageFunctionsMixin";

	export default {
		name: "StoreButtonsBox",
		mixins: [pageFunctionsMixin],
		computed: {
			iosLink(){
				return process.env.VUE_APP_IOS_STORE_LINK;
			},
			googleLink(){
				return process.env.VUE_APP_GOOGLE_STORE_LINK;
			}
		}
	}
</script>

<style scoped lang="scss">
	.store-buttons-box {
		display: flex;

		.image-button {
			margin-right: 20px;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.image-button {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 15px 25px;
		border-radius: 10px;
		box-shadow: 0 10px 20px transparentize($blackColor, .84);
		position: relative;
		z-index: 1;

		img {
			height: 35px;
		}

		&.apple-store {
			background: $blackColor;
		}

		&.google-play {
			background: $whiteColor;
		}

		&:hover {
			box-shadow: 0 10px 20px transparentize($blackColor, .7);
		}
	}

	@media($mobileM) {
		.store-buttons-box {
			flex-direction: column;
			max-width: 310px;

			.image-button {
				margin-right: 0;
				margin-bottom: 15px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
</style>
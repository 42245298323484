<template>
	<main class="app-main">
		<div class="content-wrap">
			<section class="content-section">
				<header class="section-header">
					<h2 class="section-title">{{ $t('routes.investments.title') }}</h2>
				</header>
				<article>
					<investments-options-form/>
					<chart-panels-group :panels-data="panelsData" :charts-data="chartsData" v-if="investmentsData"/>
				</article>
			</section>
		</div>
	</main>
</template>

<script>
import InvestmentsOptionsForm from "@/components/forms/InvestmentsOptionsForm";
import ChartPanelsGroup from "@/components/containers/ChartPanelsGroup";

export default {
	name: "InvestmentsHome",
	components: {
		InvestmentsOptionsForm,
		ChartPanelsGroup
	},
	data() {
		return {}
	},
	computed: {
		chartsData() {
			let data = [
				{
					chartOptions: {
						chart: {
							type: 'donut',
						},
						legend: {
							show: true,
							position: 'right',
							fontSize: '16px',
							fontFamily: 'Poppins',
							labels: {
								colors: 'inherit',
							},
							markers: {
								width: 15,
								height: 15,
								radius: 3,
								offsetX: -5,
								offsetY: 5
							},
						},
						colors: [
							'#f9a03a',
							'#2BA748',
							'#008FFB',
							'#FF4560',
							'#775DD0'
						],
						plotOptions: {
							pie: {
								borderWidth: 0
							}
						},
						stroke: {
							show: true,
							curve: 'smooth',
							lineCap: 'butt',
							colors: [
								'#f9a03a',
								'#2BA748',
								'#008FFB',
								'#FF4560',
								'#775DD0'
							],
							width: 2,
							dashArray: 1,
						},
						tooltip: {
							enabled: true,
							offset: 100,
							y: {
								show: true,
								formatter: (seriesName) => {
									return `${seriesName} ${process.env.VUE_APP_CURRENCY}`;
								},
							},
						},
						dataLabels: {
							enabled: true,
							dropShadow: {
								enabled: true,
								top: 2,
								left: 1,
								blur: 2,
								color: '#000',
								opacity: 0.2,
							},
						},
						responsive: [{
							breakpoint: 991,
							options: {
								legend: {
									position: 'bottom',
									markers: {
										offsetX: -2,
										offsetY: 0
									}
								}
							},
						}]
					},
					totalBalance: this.investmentsData.total,
				}
			];

			let labels = [];
			let series = [];
			if (this.checkPermission('EIC')){
				labels.push('EIC');
				series.push(this.investmentsData.total_eic);
			}
			if (this.checkPermission('IAD')){
				labels.push('IAD');
				series.push(this.investmentsData.total_iad);
			}
			if (this.checkPermission('Conseq')){
				labels.push('Conseq');
				series.push(this.investmentsData.total_conseq);
			}
			if (this.checkPermission('Investika')){
				labels.push('Investika');
				series.push(this.investmentsData.total_investika);
			}

			data[0].chartOptions.labels = labels;
			data[0].series = series;

			return data;
		},
		investmentsData() {
			return this.$store.state.views.investments;
		},
		panelsData() {
			let panels = [];

			if (this.checkPermission('EIC')){
				const date = new Date().toLocaleDateString('en-GB').split('/').join('.');
				panels.push({
					name: `EIC`,
					description: this.$t('investments.eic_balance'),
					category: this.$t('investments.investment'),
					price: this.investmentsData.total_eic.toFixed(2),
					type: 'investment',
					detailUrl: this.$t('routes.investments_eic.path'),
					redirectDetail: this.investmentsData.accounts_eic.length,
				});
			}

			if (this.checkPermission('IAD')){
				panels.push({
					name: 'IAD',
					description: this.$t('investments.iad_balance'),
					category: this.$t('investments.investment'),
					price: this.investmentsData.total_iad.toFixed(2),
					type: 'investment',
					detailUrl: this.$t('routes.investments_iad.path'),
					redirectDetail: this.investmentsData.accounts_iad.length,
				});
			}

			if (this.checkPermission('Conseq')){
				panels.push({
					name: 'Conseq',
					description: this.$t('investments.conseq_balance'),
					category: this.$t('investments.investment'),
					price: this.investmentsData.total_conseq.toFixed(2),
					type: 'investment',
					detailUrl: this.$t('routes.investments_conseq.path'),
					redirectDetail: this.investmentsData.accounts_conseq.length,
				});
			}

			if (this.checkPermission('Investika')){
				panels.push({
					name: 'Investika',
					description: this.$t('investments.investika_balance'),
					category: this.$t('investments.investment'),
					price: this.investmentsData.total_investika.toFixed(2),
					type: 'investment',
					detailUrl: this.$t('routes.investments_investika.path'),
					redirectDetail: this.investmentsData.accounts_investika.length,
				});
			}

			return panels;
		}
	},
	methods: {
		checkPermission(type){
			let app = process.env.VUE_APP_TYPE;
			let allowedInvestments = this.$store.state.app.allowedInvestments[app];

			return allowedInvestments.includes(type);
		}
	},
	mounted() {

	}
}
</script>

<style scoped lang="scss">
.investments-options-form {
	max-width: 600px;
	margin-bottom: 15px;
}
</style>